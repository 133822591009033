angular.module('todoApp', [
    'ngRoute',
    'ngResource',
    'ngStorage',
    'ui.bootstrap',
    'appRoutes',
    'enterStroke',
    'MainController',
    'TodoController',
    'UserController',
    'UserService',
    'TodoService',
    'ngTable',
    'AuswertungController',
    'RueckmeldungController',
    'AuswertungService',
    'RueckmeldungService',
    'GebotService',
]);

