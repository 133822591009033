angular.module('AuswertungController', ['ngTable', 'ui.bootstrap']).controller('AuswertungController', ['$scope', '$location', '$routeParams', '$http', 'Auswertung', 'Gebot', '$filter', 'NgTableParams', '$modal', '$timeout',
    function ($scope, $location, $routeParams, $http, Auswertung, Gebot, $filter, NgTableParams, $modal, $timeout) {

        $scope.downloadfile = {target: "", targetShortName: ""};
        $scope.files = [];
        $scope.vorgaenge = [];
        $scope.vorgaengeRueckmeldung = [];
        $scope.bestellImportSelected = {};
        $scope.checkbox_iaSelected = {};
        $scope.checkbox_pgSelected = {};
        $scope.state = {
            vorgang: {},
            selectedVorgangsZeile: {},
            lastVorgangsNr: 0,
        }
        $scope.status = 'done';

        $scope.findAll = function () {
            Auswertung.get({auswertungId: 'alle'}, function (response) {
                    $scope.vorgaenge = response.data;
                },
                function (error) {
                    console.log(error);
                })
        }


        $scope.goAuswertung = function () {
            $location.path('auswertung/');
        }

        $scope.goRueckmeldungOffen = function () {
            $location.path('rueckmeldung/offen');
        }

        $scope.edit = function (vorgang) {
            $scope.state.vorgang = vorgang;
            var fileBasename = vorgang.datei.indexOf("\.") > 0 ? vorgang.datei.split(".")[0] : vorgang.datei;

            if ($scope.authenticatedUser.role != 'nagel') {
                $location.path('auswertung/vorgangeinzeln/' + fileBasename);
            } else {
                $location.path('auswertung/vorgang/' + fileBasename);
            }
        }

        $scope.editRueckmeldung = function (vorgang) {
            $scope.state.vorgang = vorgang;
            var fileBasename = vorgang.datei.indexOf("\.") > 0 ? vorgang.datei.split(".")[0] : vorgang.datei;

            $location.path('rueckmeldung/vorgang/' + fileBasename);
        }

        function buildDataWithSumRows(response) {
            /* Append Sum Row */
            var last_vorgangsnr = 0;
            var last_vorgangid = 0;
            var last_checkbox_ia = 0;
            var last_checkbox_pg = 0;
            var idx = 0;
            var extendedArray = [];

            for (var key in response) {
                idx--;

                if (last_vorgangsnr != 0 && last_vorgangsnr != response[key].vorgangsnr) {
                    extendedArray.push({
                        vorgang_id: idx,
                        isSumRow: true,
                        'last_vorgang_id': last_vorgangid,
                        'last_vorgangs_nr': last_vorgangsnr,
                        'checkbox_ia': last_checkbox_ia,
                        'checkbox_pg': last_checkbox_pg,
                    });
                } else {
                    response[key]['isSumRow'] = false;
                }

                last_vorgangsnr = response[key].vorgangsnr;
                last_vorgangid = response[key].vorgang_id;
                last_checkbox_ia = response[key].checkbox_ia;
                last_checkbox_pg = response[key].checkbox_pg;

                extendedArray.push(response[key]);
            }
            // addition for last entry
            extendedArray.push({
                vorgang_id: idx,
                isSumRow: true,
                'last_vorgang_id': last_vorgangid,
                'last_vorgangs_nr': last_vorgangsnr,
                'checkbox_ia': last_checkbox_ia,
                'checkbox_pg': last_checkbox_pg
            });

            return extendedArray;
        }

        $scope.findByFile = function () {
            var dateiName = $location.path().split('/').slice(-1)[0];
            $scope.state.vorgang.datei = dateiName;

            Auswertung.get({auswertungId: dateiName}, function (response) {

                    $scope.empfehlung = response.empfehlung;
                    $scope.vorgaenge = buildDataWithSumRows(response.data);
                    $scope.gebote = response.gebote;

                },
                function (response) {
                    console.log(response);
                    if (typeof response.data.error != "undefined" && response.data.error.match(/token/)) {
                        $scope.logout();
                    }
                })
        }

        $scope.updateVKE = function (vorgang) {
            var _vorgang = new Auswertung(vorgang);

            _vorgang.$save().then(function (data) {
                console.log(data);
            }, function (data) {
                console.log(error);
                if (typeof data.error != "undefined" && data.error.match(/token/)) {
                    $scope.logout();
                }
            });
        }

        $scope.toggleKeinGebot = function (vorgang) {

            $scope.status = 'running';
            vorgang.keingebot_at = vorgang.keingebot_at == null || vorgang.keingebot_at.indexOf('0000') == 0 ? new Date().toISOString() : '0000-00-00 00:00:00';
            var _auswertung = new Auswertung(vorgang);
            _auswertung.$save().then(function (data) {
                console.log(data);
                $scope.status = 'done';
            }, function (response) {
                console.log(response);
                if (typeof response.data.error != "undefined" && response.data.error.match(/token/)) {
                    $scope.logout();
                }
            });
        }

        $scope.calcSumme = function (gebot, vorgangZeile, bieterIndex) {
            $scope.status = 'running';

            $scope.setSelectedVorgang(vorgangZeile);
            var _preis = typeof gebot.preis == "undefined" || gebot.preis.length == 0 ? "0" : gebot.preis;
            _preis = parseFloat(_preis.replace(",", "."));
            gebot.summe = _preis * $scope.state.selectedVorgangsZeile.vke;
            gebot.bieter = bieterIndex;

            var _gebot = new Gebot(gebot);
            _gebot.preis = parseFloat(_gebot.preis.replace(",", "."));
            _gebot.$save().then(function (data) {
                $scope.status = 'done';
            }, function (response) {
                console.log(response);
                if (typeof response.data.error != "undefined" && response.data.error.match(/token/)) {
                    $scope.logout();
                }
            });
        }

        $scope.setCustomerSelected = function (customer2LC, vorgngsnr) {
            $scope.status = 'running';


        }

        $scope.getVorgangsSumme = function (v_g, bieter_index) {
            var sum = 0.0;

            for (var idx in $scope.gebote) {
                //for (var idx2 in $scope.gebote[idx]) {
                var val = $scope.gebote[idx][bieter_index];

                if (typeof val == "undefined") {
                    continue;
                }

                var vorgangs_nr = typeof v_g.vorgangsnr !== "undefined" ? v_g.vorgangsnr :
                    typeof v_g.last_vorgangs_nr !== "undefined" ? v_g.last_vorgangs_nr : "";

                if (vorgangs_nr == val.vorgangsnr) {
                    sum += val.summe;
                }
                
                //}
            }

            var sum_parts = $filter('number')(sum, 2).split('.');

            return sum_parts[0].replace(',', '.') + ',' + sum_parts[1];
        }

        $scope.getVorgangsSummeEmpfohlen = function (v_g, bieter_index) {
            var sum = 0.0;

            for (var idx in $scope.empfehlung) {

                var val = $scope.empfehlung[idx][0];
                if (typeof val != "undefined" && v_g.last_vorgangs_nr == val.vorgangsnr) {
                    sum += val.summe;
                }

            }

            var sum_parts = $filter('number')(sum, 2).split('.');

            return sum_parts[0].replace(',', '.') + ',' + sum_parts[1];
        }

        $scope.setSelectedVorgang = function (vorgangZeile) {
            $scope.state.idSelectedVorgang = vorgangZeile.vorgang_id;
            $scope.state.selectedVorgangsZeile = vorgangZeile;
        }

        $scope.onsubmit = function () {
            $scope.uploadNagelForm.nagelpdf = $scope.files[0];

            $('#btnExcelUmwandeln').button('loading');

            $http({
                method: 'POST',
                url: '/api/auswertung/upload',
                processData: false,
                transformRequest: function (data) {
                    var formData = new FormData();
                    formData.append("nagelpdf", $scope.uploadNagelForm.nagelpdf);
                    return formData;
                },
                data: $scope.uploadNagelForm,
                headers: {
                    'Content-Type': undefined
                }
            }).success(function (data) {
                // alert(data);
                $('#btnExcelUmwandeln').button('reset');
                $scope.downloadfile.target = data.result;
                $scope.downloadfile.targetShortName = data.result.split('/').slice(-1)[0];

                $scope.findAll();

            }).error(function (data) {
                console.log(data);
                $('#btnExcelUmwandeln').button('reset');
                $scope.downloadfile.target = "";

                if (typeof data.error != "undefined" && data.error.match(/token/)) {
                    $scope.logout();
                }
            });
        };

        $scope.uploadedFile = function (element) {
            $scope.currentFile = element.files[0];
            var reader = new FileReader();

            reader.onload = function (event) {
                $scope.image_source = event.target.result
                $scope.$apply(function ($scope) {
                    $scope.files = element.files;
                });
            }
            reader.readAsDataURL(element.files[0]);
        }

        $scope.openModalVorgangAbschliessen = function () {

            var modalInstance = $modal.open({
                templateUrl: 'vorgangAbschliessenModal.html',
                controller: 'ModalVorgangAbschliessenCtrl',
                size: 'md',
                resolve: {
                    vorgang: function () {
                        return typeof $scope.vorgang == "undefined" ? $scope.vorgaenge[0] : $scope.vorgang;
                    },
                    state: function () {
                        return $scope.state;
                    },
                    rootScope: function () {
                        return $scope;
                    }
                }
            });

            modalInstance.result.then(function (state) {

                if (state.action != 'save') {
                    return;
                }

                var vorgang = state.vorgang;

                vorgang.id = vorgang.vorgang_id;
                vorgang.abgeschlossen_at = 'now';

                var _vorgang = new Auswertung(vorgang);

                _vorgang.$update().then(function (data) {
                    state.rootScope.vorgaenge[0].abgeschlossen_at = data.vorgang.abgeschlossen_at;
                    console.log(data);
                });

            }, function (data) {

                if (typeof data.error != "undefined" && data.error.match(/token/)) {
                    $scope.logout();
                }
            });
        };

        $scope.openModalVorgangLoeschen = function (vorgang) {

            var modalInstance = $modal.open({
                templateUrl: 'vorgangLoeschenModal.html',
                controller: 'ModalVorgangLoeschenCtrl',
                size: 'md',
                resolve: {
                    vorgang: function () {
                        return vorgang;
                    }
                }
            });

            modalInstance.result.then(function (state) {

                if (state.action != 'delete') {
                    return;
                }

                vorgang.delete = 'now';
                vorgang.id = vorgang.vorgang_id;
                vorgang.auswertungId = vorgang.vorgang_id;

                var _vorgang = new Auswertung(vorgang);

                _vorgang.$delete().then(function (data) {
                    console.log(data);
                    $scope.findAll();
                });

            }, function () {

            });
        };

        $scope.doSpeichern = function () {
            $('#btnSpeichern').button('loading');

            console.log($scope.gebote);
            $scope.status = 'running';
            $http({
                method: 'POST',
                url: '/api/gebote',
                processData: false,
                data: {
                    data: {gebote: $scope.gebote}
                },

            }).success(function (data) {
                $scope.status = 'done';
                $('#btnSpeichern').button('reset');

            }).error(function (data) {
                $('#btnSpeichern').button('reset');
                $scope.downloadfile.target = "";

                if (typeof data.error != "undefined" && data.error.match(/token/)) {
                    $scope.logout();
                }
            });

        }

        $scope.vorgangSpeichern = function () {
            $http({
                method: 'PUT',
                url: '/api/auswertung/' + $scope.state.vorgang.datei,
                data: {abgeschlossen_at: 'now', datei: $scope.state.vorgang.datei},
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }).success(function (data) {
                console.log(data);

            }).error(function (data) {
                console.log(data);

            });
        }

        /**
         *
         * @param url, e.g. '/api/excel/downloadexcel'
         * @param mimeType, e.g. 'application/pdf'
         * @param params, e.g. {'vorgangsnr':['12345']}
         */
        var downloadDocumentViaAPI = function (url, mimeType, params) {

            var extension = 'pdf';
            if (mimeType.toLowerCase().match(/xls/)) {
                extension = 'xlsx';
            }

            var requestData = {'dateiName': $scope.state.vorgang.datei, 'params': params};

            $http.post(url, requestData, {
                responseType: 'arraybuffer',
                //headers: header
            })
                .success(function (response) {
                    //console.log(response);
                    // application/pdf
                    // application/vnd.ms-excel

                    if (extension == 'pdf') {
                        $('#downloadPDFBtn').button('reset');
                    } else {
                        $('#downloadExcelBtn_pg').button('reset');
                        $('#downloadExcelBtn_ia').button('reset');
                    }

                    var file = new Blob([response], {type: mimeType});

                    var isChrome = window.chrome || navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
                    var isIE = /*@cc_on!@*/false || !!document.documentMode;
                    var isEdge = !isIE && !!window.StyleMedia;

                    if (isChrome) {
                        var url = window.URL || window.webkitURL;

                        var downloadLink = angular.element('<a></a>');
                        downloadLink.attr('href', url.createObjectURL(file));
                        downloadLink.attr('target', '_self');
                        downloadLink.attr('download', $scope.state.vorgang.datei + '.' + extension);
                        downloadLink[0].click();

                    } else if (isEdge || isIE) {
                        window.navigator.msSaveOrOpenBlob(file, $scope.state.vorgang.datei + '.' + extension);

                    } else {
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }

                })
        }

        $scope.downloadPDF = function () {

            $('#downloadPDFBtn').button('loading');
            downloadDocumentViaAPI('/api/excel/downloadpdf', 'pdf');
        };

        /**
         *  saves now also the IA PG checkboxes
         *
         * @param iaORpg
         */
        $scope.downloadBestellungImport = function (iaORpg = '') {

            $('#downloadExcelBtn_' + iaORpg).button('loading');

            var params = {vorgangsnr: []};

            for (var vorgangsnr in $scope.bestellImportSelected) {

                if (iaORpg == 'ia') {
                    $scope.checkbox_iaSelected[vorgangsnr] = true;
                }
                if (iaORpg == 'pg') {
                    $scope.checkbox_pgSelected[vorgangsnr] = true;
                }
                if (true == $scope.bestellImportSelected[vorgangsnr]) {
                    params['vorgangsnr'].push(vorgangsnr);
                }
            }
            $scope.saveIAPGCheckboxes();
            downloadDocumentViaAPI('/api/excel/downloadexcel', 'xls', params);
        };

        /**
         * group function for save
         */
        $scope.vorgangEinzelnSave = function () {
            $('#saveBtn').button('loading');
            $scope.saveIAPGCheckboxes();
            $('#saveBtn').button('reset');
        }

        $scope.saveIAPGCheckboxes = function () {

            var params = {
                checkbox: {
                    ia: {vorgangsnr: {}},
                    pg: {vorgangsnr: {}}
                }
            };

            for (var vorgangsnr in $scope.checkbox_iaSelected) {
                params['checkbox']['ia']['vorgangsnr'][vorgangsnr] = $scope.checkbox_iaSelected[vorgangsnr];
            }

            for (var vorgangsnr in $scope.checkbox_pgSelected) {
                params['checkbox']['pg']['vorgangsnr'][vorgangsnr] = $scope.checkbox_pgSelected[vorgangsnr];
            }

            $http({
                method: 'PUT',
                url: '/api/auswertung/' + $scope.state.vorgang.datei,
                data: {
                    datei: $scope.state.vorgang.datei,
                    checkbox_ia: params['checkbox']['ia']['vorgangsnr'],
                    checkbox_pg: params['checkbox']['pg']['vorgangsnr'],
                }
            }).success(function (data) {
                console.log(data);

            }).error(function (data) {
                console.log(data);

            });

        };

    }
]).controller('ModalVorgangAbschliessenCtrl', ['$scope', '$modalInstance', 'state', 'vorgang', 'rootScope', function ($scope, $modalInstance, state, vorgang, rootScope) {

    $scope.state = state;
    $scope.vorgang = vorgang;
    $scope.rootScope = rootScope;

    $scope.ok = function () {
        $modalInstance.close({action: 'save', vorgang: $scope.vorgang, rootScope: $scope.rootScope});
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
}]).controller('ModalVorgangLoeschenCtrl', ['$scope', '$modalInstance', 'vorgang', function ($scope, $modalInstance, vorgang) {

    $scope.vorgang = vorgang;

    $scope.ok = function () {
        $modalInstance.close({action: 'delete', vorgang: $scope.vorgang});
    };
    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
}]);
