angular.module('AuswertungService', []).factory('Auswertung', ['$resource',
    function ($resource) {
        return $resource('/api/auswertung/:auswertungId', {
            auswertungId: '@id'
        }, {
            update: {
                method: 'PUT'
            }
        });
    }
]);