angular.module('RueckmeldungService', []).factory('Rueckmeldung', ['$resource',
    function ($resource) {
        return $resource('/api/rueckmeldung/:rueckmeldungId', {
            rueckmeldungId: '@id'
        }, {
            update: {
                method: 'PUT'
            }
        });
    }
]);